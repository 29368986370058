<template>
	<div>
		<!-- <button @click="toggleTheme">Toggle Theme</button> -->

		<div
			@click="toggleTheme(),toggleActive = !toggleActive"
			class="w-26 h-10 flex items-center rounded-lg p-1 duration-300 ease-in-out"
			:class="{'bg-green-400': !toggleActive ,'bg-yellow-400': toggleActive }"
				>
			<div class="z-10 select-none">
				<!-- light -->
				<svg
					id="theme-toggle-light-icon"
					class="w-8 h-8"
					fill="rgb(55, 65, 81)"
					viewBox="0 0 20 20"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
					d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
					fill-rule="evenodd"
					clip-rule="evenodd"
					></path>
				</svg>

			</div>
			<div class="z-10 ml-auto select-none">
				<!-- dark -->
				<svg
					id="theme-toggle-dark-icon"
					class="w-7 h-7"
					fill="rgb(55, 65, 81)"
					viewBox="0 0 20 20"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
					d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"
					></path>
				</svg>
			</div>
			<div
				class="absolute bg-gray-200 w-8 h-8 rounded-lg shadow-md transform duration-300 ease-in-out"
				:class="{ 'translate-x-8': toggleActive }"
			></div>
		</div>

	</div>
</template>

<script>
import { ref } from "vue"           
export default {
	setup() {
		const toggleActive = ref(false);
		return { toggleActive };
	},
  methods: {
	toggleTheme() {
		document.documentElement.classList.toggle('dark') // Добавляйте или удаляйте класс 'dark' со страницы
		
	},
  },
}
</script>

<style scoped>

</style>
